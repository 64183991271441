<template>
  <section class="section section__benefits" v-if="advantages.length">
    <h2 class="land_title">Почему Лизинг-Трейд?</h2>
    <div class="adv">
      <div v-for="(advantage, index) in advantages" :key="index" class="adv__item">
        <div class="adv__icon" v-html="advantage.icon"></div>
        <div class="adv__title">{{ advantage.title }}</div>
        <div class="adv__desc">
          <EditorJSComponent :text="getAdvantagesDescription(advantage)" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import EditorJSComponent from "@/components/EditorJSComponent.vue";

export default {
  name: "AboutAdvantagesComponent",
  computed: {
    pageData() {
      if (this.$store.state.about_page && this.$store.state.about_page.page) {
        return this.$store.state.about_page.page;
      } else {
        return undefined;
      }
    },
    advantages() {
      if (
        this.$store.state.about_page &&
        this.$store.state.about_page.advantages &&
        this.$store.state.about_page.advantages.length
      ) {
        return this.$store.state.about_page.advantages.map((adv) => adv.advantage);
      } else {
        return [];
      }
    },
  },
  methods: {
    getAdvantagesDescription(adv) {
      if (adv.description) {
        let haveError = false;
        try {
          JSON.parse(adv.description);
        } catch (e) {
          haveError = true;
        }
        if (!haveError) {
          return JSON.parse(adv.description);
        } else {
          return {
            blocks: [
              {
                type: "paragraph",
                data: { text: adv.description },
              },
            ],
          };
        }
      }
      return {
        blocks: [],
      };
    },
  },
  components: {
    EditorJSComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"

.adv
  display flex
  align-items stretch
  gap 30px
  flex-wrap wrap

  +below(768px)
    gap 15px

.adv__item
  flex 30%
  border 1px solid @css{rgb(var(--main_gray))}
  border-radius 15px
  padding 30px
  display flex
  align-items center
  justify-content flex-start
  flex-direction column
  gap 10px

  &:hover
    .adv__icon
      transform scale(1.05)

  +below(768px)
    flex auto
    padding 15px

.adv__icon
  transition all 0.3s

  svg
    width 100%
    height 100%
    max-width 80px
    max-height 80px
    fill @css{rgb(var(--main_color))}

    +below(768px)
      max-width 60px
      max-height 60px

.adv__title
  font-family var(--font_bold)
  font-size 1.2em
  text-align center

  +below(768px)
    font-size 1em

.adv__desc
  text-align center

  +below(768px)
    font-size 0.9em
    line-height 1.5em
</style>
