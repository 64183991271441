<template>
  <section class="section section_benefits lp_benefits">
    <h2 class="land_title">Преимущества Лизинга недвижимости</h2>
    <div class="benefits">
      <div class="benefits__item" v-for="(item, index) in benefits" :key="index">
        <div class="benefits__ico" v-html="icon"></div>
        <div class="benefits__body">
          <div class="benefits__title">{{ item.title }}</div>
          <div class="benefits__text">{{ item.text }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      benefitsTitle: "Преимущества Лизинга недвижимости",
      benefits: [
        {
          id: 1,
          title: "Экономия на налогах от 20%",
          text: "Лизинговый платеж можно отнести к себестоимости, что позволяет сэкономить при уплате налога на прибыль. НДС будет зачтен в полном объеме по всему договору лизинга.",
        },
        {
          id: 2,
          title: "Быстрый старт с минимальным авансом",
          text: "Для оформления лизинга вам потребуется минимальный первоначальный платеж.",
        },
        {
          id: 3,
          title: "Юридическое сопровождение",
          text: "Мы берем на себя полное юридическое сопровождение сделки, проверяем чистоту объекта, проводим все регистрационные процедуры.",
        },
        {
          id: 4,
          title: "Расходы на содержание помещения осуществляются от организации",
          text: "Все расходы, связанные с объектом недвижимости, оплачиваются со счета организации.",
        },
        {
          id: 5,
          title: "Без верхнего ограничения по цене объекта",
          text: "Вы можете оформить в лизинг недвижимость от 4 млн. руб. В нашей компании нет ограничения по максимальной стоимости объекта.",
        },
        {
          id: 6,
          title: "Индивидуальные графики платежей",
          text: "Вариативные графики платежей: равномерные, убывающие, смешанные и максимально адаптированные под ваш бизнес.",
        },
      ],
      icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 13"><path d="M17.7 0.3C18.1 0.7 18.1 1.3 17.7 1.7L6.7 12.7C6.3 13.1 5.7 13.1 5.3 12.7L0.3 7.7C-0.1 7.3-0.1 6.7 0.3 6.3 0.7 5.9 1.3 5.9 1.7 6.3L6 10.6 16.3 0.3C16.7-0.1 17.3-0.1 17.7 0.3Z"></path></svg>',
    };
  },
};
</script>

<style lang="stylus">

.benefits
  display grid
  grid-template-columns repeat(3, 1fr)
  gap 30px

  +below(980px)
    grid-template-columns repeat(2, 1fr)

  +below(768px)
    gap 15px

  +below(480px)
    grid-template-columns 1fr

.benefits__item
  border 1px solid @css{rgb(var(--main_gray))}
  border-radius 15px
  padding 30px
  display flex
  align-items flex-start
  gap 20px

  &:hover
    .benefits__ico
      transform scale(1.05)

  +below(768px)
    flex-direction column
    align-items initial
    justify-content flex-start
    padding 15px

.benefits__ico
  width 50px
  height 50px
  border-radius 50%
  background @css{rgb(var(--main_color))}
  color @css{rgb(var(--main_white))}
  display flex
  align-items center
  justify-content center
  transition all 0.3s

  svg
    width 100%
    height 100%
    max-width 20px
    max-height 20px
    fill @css{rgb(var(--main_white))}

.benefits__body
  flex 1

.benefits__title
  font-family var(--font_bold)

.benefits__text
  font-size 0.8em
  line-height 1.5em
  margin-top 10px
</style>
