<template>
  <main class="main lp">
    <div class="container">
      <WelcomeComponent />
      <Benefits />
      <section class="section section_calc">
        <Calculator :vehicles="vehicles" :active-vehicle="vehicles.find((v) => v.id === 6)" />
      </section>
      <section class="section section_calc">
        <h2 class="section__title">Частые вопросы</h2>
        <FaqSection :faq="faq" />
      </section>
      <AdvantagesComponent class="advant-wrap" />
      <section class="section section_form">
        <ProgramRequest />
      </section>
    </div>
  </main>
</template>

<script>
import ProgramRequest from "@/views/vehicles/components/Request.vue";
import AdvantagesComponent from "@/views/leasing-land/components/Advantages.vue";
import Calculator from "@/components/Calculator.vue";
import WelcomeComponent from "@/views/leasing-land/components/Welcome.vue";
import Benefits from "./components/Benefits.vue";
import { cityIn } from "lvovich";
import FaqSection from "./components/Faq.vue";

export default {
  name: "LeasingPage",
  async asyncData({ store, link }) {
    store.state.alternateLink = link;
    await store.dispatch("GET_LANDING_PAGE");
  },
  computed: {
    page() {
      let page;
      if (this.$store.state.landing_page && this.$store.state.landing_page.page) {
        page = this.$store.state.landing_page.page;
      }
      return page;
    },
    faq() {
      if (this.$store.state.landing_page && this.$store.state.landing_page.faq) {
        return this.$store.state.landing_page.faq;
      } else {
        return [];
      }
    },
    vehicles() {
      if (this.$store.state.landing_page && this.$store.state.landing_page.vehicles) {
        return this.$store.state.landing_page.vehicles;
      } else {
        return [];
      }
    },
  },
  methods: {
    getImg(item) {
      if (item.head_img && item.head_img.img && item.head_img.img.url) {
        return this.$store.state.api + item.head_img.img.url;
      }
    },
  },

  metaInfo() {
    let alternateLink = this.$store.state.alternateLink;
    let page = {};
    let city = this.$store.state.cities.find((c) => c.title_eng === this.$store.state.geoData.city);
    city = cityIn(city ? city.title : "Казань");
    if (this.$store.state.home_page && this.$store.state.home_page.page) {
      page = this.$store.state.home_page.page;
    }
    const title = page.meta_title || page.title || "".replace(/{{ city }}/g, `в ${city}`);
    let img = this.getImg(page) || "";
    return {
      title,
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: title,
            description: page.meta_description || "".replace(/{{ city }}/g, `в ${city}`),
          },
        },
      ],
      link: [
        { rel: "canonical", href: alternateLink },
        { rel: "alternate", hreflang: "x-default", href: alternateLink },
      ],
      meta: [
        {
          vmid: "keywords",
          name: "keywords",
          content: page.meta_keywords,
        },
        {
          vmid: "description",
          name: "description",
          content: page.meta_description || "".replace(/{{ city }}/g, `в ${city}`),
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: title,
        },
        {
          vmid: "og:image",
          name: "og:image",
          content: img,
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: page.meta_description || "".replace(/{{ city }}/g, `в ${city}`),
        },
      ],
    };
  },
  components: {
    FaqSection,
    WelcomeComponent,
    Calculator,
    AdvantagesComponent,
    ProgramRequest,
    Benefits,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
@import "~@/styles/parts/home/index.styl"

.lp
  h2
    text-transform uppercase

  .container
    max-width 1200px

  .section
    padding-top 70px

    +below(980px)
      padding-top 50px

    +below(768px)
      padding-top 30px

.section_calc
  .calc
    background none
    margin 0

    .container
      padding 0
      display block
      max-width none

    .calc__form
      max-width none

.section_form
  padding-bottom 80px

  .consult
    margin 0

  .container
    padding 0
    max-width none
</style>
