<template>
  <ul class="faq" v-if="faq && faq.length">
    <li v-for="(q, i) in faq" :key="i">
      <a
        href="#"
        class="faq__header"
        :class="{ active: activeFaq === i }"
        @click.prevent="activeFaq === i ? (activeFaq = null) : (activeFaq = i)"
      >
        <h2 class="faq__title">{{ q.title }}</h2>
        <FaqArrowIcon />
      </a>
      <div v-if="hasText(q.answer)" class="faq__body" v-show="activeFaq === i">
        <EditorJSComponent v-if="isJson(q.answer)" :text="JSON.parse(q.answer)" />
        <template v-else v-html="q.answer">{{ q.answer }}</template>
      </div>
    </li>
  </ul>
</template>

<script>
import FaqArrowIcon from "components/svg/FaqArrow.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "FaqSection",
  props: {
    faq: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    activeFaq: null,
  }),
  components: {
    FaqArrowIcon,
    EditorJSComponent,
  },
  methods: {
    getImg(item) {
      if (item.head_img && item.head_img.img && item.head_img.img.url) {
        return this.$store.state.api + item.head_img.img.url;
      }
    },
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    hasText(text) {
      if (this.isJson(text)) {
        const parsedText = JSON.parse(text);
        return !!(parsedText && parsedText.blocks && parsedText.blocks.length);
      }
      return !!text;
    },
  },
};
</script>
<style lang="stylus">
@import "~@/styles/global/global.styl"
@import "~@/styles/parts/faq.styl"
</style>
