<template>
  <section class="section section_lp">
    <div class="leasing-welcom lp__welcome lp_welcome">
      <div class="lp_welcome__img">
        <LottieComponent json="/public/house.json" />
      </div>
      <div class="welcome__content">
        <h2 class="welcome__content__title land_title">Лизинг недвижимости</h2>
        <span class="welcome__content__subtitle">Жилая и Коммерческая недвижимость</span>
        <ul class="welcome__list">
          <li v-for="(item, index) in parameters" :key="index">
            <ListDotIcon />
            <span v-html="item.title"></span>
          </li>
        </ul>
        <div class="welcome__actions">
          <router-link :to="{ name: 'request' }" class="btn">Отправить заявку</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ListDotIcon from "@/components/svg/ListDot.vue";
import LottieComponent from "components/Lottie.vue";

export default {
  components: {
    ListDotIcon,
    LottieComponent,
  },
  data() {
    return {
      parameters: [
        {
          title: "Аванс от 10%",
        },
        {
          title: "Срок до 84 месяцев",
        },
        {
          title: "От 4 млн. рублей",
        },
      ],
    };
  },
};
</script>

<style lang="stylus">

.lp__welcome
  display flex
  align-items center
  gap 50px

  .welcome__content
    flex 2

  +below(440px)
    flex-direction column
    justify-content flex-start

.lp_welcome__img
  flex 1

  .lottie
    transform scale(2)

    +below(440px)
      transform scale(1)
</style>
